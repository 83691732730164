/**
 * returns unix timestamp for x minutes ago
 * @param x minutes as a number
 */
 export const minutesAgo = (x: number) => {
    const now = new Date().getTime();
    return new Date(now - x * 60000).getTime();
  };
  
  /**
   * subtracts two dates for use in 33-together timer
   * param (Date) dateA is the ending date object
   * param (Date) dateB is the current date object
   * returns days, hours, minutes, seconds
   * NOTE: this func previously used parseInt() to convert to whole numbers, however, typescript doesn't like
   * ... using parseInt on number params. It only allows parseInt on string params. So we converted usage to
   * ... Math.trunc which accomplishes the same result as parseInt.
   */
  export const subtractDates = (dateA: Date, dateB: Date) => {
    const msA: number = dateA.getTime();
    const msB: number = dateB.getTime();
  
    let diff: number = msA - msB;
  
    let days = 0;
    if (diff >= 86400000) {
      days = Math.trunc(diff / 86400000);
      diff -= days * 86400000;
    }
  
    let hours = 0;
    if (days || diff >= 3600000) {
      hours = Math.trunc(diff / 3600000);
      diff -= hours * 3600000;
    }
  
    let minutes = 0;
    if (hours || diff >= 60000) {
      minutes = Math.trunc(diff / 60000);
      diff -= minutes * 60000;
    }
  
    let seconds = 0;
    if (minutes || diff >= 1000) {
      seconds = Math.trunc(diff / 1000);
    }
    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };